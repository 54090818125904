
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {FAS_FILE_API} from '../../assets/constants/ApiUrl'
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
interface FileVersion {
	summary:string,
	submitter:string,
	versionID:string,
	fileName:string,
	timeStamp:string,
	fileSize:string
}

interface FileVersions{
	fileid:string,
	filterKey:string,
	owner:string,
	versions: Array<FileVersion>
}

export const fetchFileVersionDetail = createAsyncThunk(
	'file/fileVersions',
	async(keyName:string,thunkapi)=>{
		try{
			const response = await axios.get(FAS_FILE_API+"/"+keyName,{withCredentials: true})
			return response.data as FileVersions
		}
		catch(e){
			console.log(e)
		}
	}
)