import React from 'react'
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useEffect,useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { useAppSelector, useAppDispatch } from '../../../redux/Hooks';
import {fetchPermittedFileList} from '../../../redux/Thunk/FileListThunk';
import Button from '@material-ui/core/Button';
import { DataGrid, GridRowsProp, GridColDef ,GridCellParams,GridToolbar} from '@material-ui/data-grid';
import Checkbox from '@mui/material/Checkbox';
import './FileUploadPopOutDialog.scss'
import { isUnparsedPrepend } from 'typescript';
import {FAS_FILE_API,FAS_FILE_ADMIN_API,FAS_FILE_ADMIN_CREATE_API,FAS_FILE_API_PRE_UPLOAD,FAS_FILE_GROUP_FILE} from '../../../assets/constants/ApiUrl'
import LinearProgress from '@mui/material/LinearProgress';
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
export default function FileUploadPopOutDialog(props) {
	const{open,dialogClose,currentUser,currentUserCompany,currentUserName,currentUserGroups,groupUsers} = props
	const dispatch = useAppDispatch()
	const [fileTitle, setFileTitle] = useState("")
	const [fileDescription, setFileDescription] = useState("")
	const [file, setFile] = useState<File>(null)
	const [progress, setProgress] = useState(0)
	// const [userPermissionList, setUserPermissionList] = useState([])
	const [permittedGroup, setPermittedGroup] = useState([])
	const [uploadDisable, setUploadDisable] = useState(false)
	const currentUserGroupsList = currentUserGroups.split(',')
	const handleFileChange = (e: React.FormEvent) => {
		const files = (e.target as HTMLInputElement).files
		if (files && files.length > 0) {
			setFile(files[0])
			setFileTitle(files[0].name)
		}
	}
	const handleClose = () => {
		var formData = new FormData()
		formData.append("title",fileTitle)
		formData.append("description",fileDescription)
		formData.append("company",currentUserCompany)
		formData.append("submitter",currentUser)
		formData.append("summary",fileDescription)
		formData.append("filename",file.name)
		formData.append("userID",currentUser)
		formData.append("username",currentUserName)
		permittedGroup.forEach(group=>{
			formData.append("groups",group)
		})
		var userPermissionList = [] 
		for(const group of permittedGroup){
			groupUsers.find(g => g.group === group ).users.map(user=>{
				userPermissionList.push({
				"userID":currentUser,
				"targetUserID":user.userID,
				"downloadPermission": true,
				"uploadPermission":true
			})})
		}
		setUploadDisable(true)
		axios.post(FAS_FILE_API_PRE_UPLOAD,{
			filename:file.name,
			userID:currentUser
		},{
			withCredentials:true
		}).then((response)=>{
			let form = new FormData()
			formData.append("key",response.data.fields.key)
			Object.keys(response.data.fields).forEach(key=> form.append(key,response.data.fields[key]))
			form.append('file',file)
			axios.post(
				response.data.url,
				form,{
					onUploadProgress: data => {

					//Set the progress value to show the progress bar
			
					setProgress(Math.round((100 * data.loaded) / data.total))
					
				  }
				}).then(()=>{
					axios.post(FAS_FILE_API,formData,{
						headers:{
							'Content-Type': 'multipart/form-data'
						},
						withCredentials: true,
					}).then((response)=> {
						const key = response.data.fileid
						axios.post(FAS_FILE_ADMIN_CREATE_API+key,{"permissions":userPermissionList},{withCredentials:true})
						axios.put(FAS_FILE_GROUP_FILE,{
							file:response.data.fileid,
							groups:permittedGroup,
							submitter:currentUser
						},{
							withCredentials:true
						})
					}).then(()=>{
						dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":'',"searchKeyValue":''}))
					}).then(()=>{
						setProgress(0)
						setFileTitle('')
						dialogClose()
						setUploadDisable(false)
					})
				})
		})

		
	};
	const handleNormalClose = ()=>{
		setProgress(0)
		setFileTitle('')
		dialogClose()
	}
	const handleGroupCheck =(target)=>(e)=>{
		var tmplist = [...permittedGroup]
		if(e.target.checked){
			tmplist.push(target)
			console.log(tmplist)
			setPermittedGroup(tmplist)
		}
		else{
			const tmplist = permittedGroup.filter(group=>group!==target)
			console.log(tmplist)
			setPermittedGroup(tmplist)
		}
	}
	const columns: GridColDef[] = [
		{field: 'col2', headerName: 'Group', flex: 150},
		{field: 'col3', headerName: 'Users', flex: 400},
		{field: 'col1', headerName: 'Permission', flex: 150,renderCell: (params: GridCellParams) =>{
			return(
				<div>
					<Checkbox onChange={handleGroupCheck(params.value)}/>
				</div>
			)
		}}
	]
	const rows: GridRowsProp = currentUserCompany==="Broalux"? groupUsers.map((group,index)=>{
		return(
			{id: index+1, col2:group.group, col3:group.users.map(user=>user.name), col1:group.group}
		)
	}): groupUsers.map((group,index)=>{
		if(currentUserGroups.includes(group.group)){
			return(
				{id: index+1, col2:group.group, col3:group.users.map(user=>user.name), col1:group.users.map(user=>user.userID)}
			)
		}
	})


	return (
		<Dialog open={open} onClose={handleNormalClose} aria-labelledby="uploadNewFile" className="uploadNewFileDialog" maxWidth={"lg"}>
			
			<DialogTitle id="uploadNewFile">Create a New Project</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Upload a file in a new project with following information
				</DialogContentText>
				<LinearProgress variant="determinate" value={progress} />
				<form className="uploadFileForm">
					<TextField
						autoFocus
						margin="normal"
						label="File"
						id="file"
						type="file"
						fullWidth
						onChange={handleFileChange}
					/>
					<TextField
						autoFocus
						id="title"
						label="Title"
						fullWidth
						onChange={(e)=>{
							setFileTitle(e.target.value)
						}}
						value={fileTitle}
					/>
					<TextField
						autoFocus
						id="Description"
						label="Description"
						fullWidth
						onChange={(e)=>{
							setFileDescription(e.target.value)
						}}
					/>
					
				</form>	
				<DataGrid className="awsUserList" rows={rows} columns={columns}/>		
			</DialogContent>
			<DialogActions>
				<Button onClick={handleNormalClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleClose} color="primary" disabled={uploadDisable}>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}
