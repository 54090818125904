import { FiberPinTwoTone } from '@material-ui/icons';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {FAS_FILE_GROUPS_API} from '../../assets/constants/ApiUrl'
import axios from 'axios'
import AxiosInterceptor from './AxiosInterceptor';
// AxiosInterceptor()


// console.log("bentest1")
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;

interface Group {
	files: Array<any>
	Group:string
}

interface FileGroups{
	Groups:Array<Group>
}


interface InputData{
	file:string,
	owner:string
}
export const fetchFileGroupsList = createAsyncThunk(
	'file/groups',
	async(data:InputData,thunkapi)=>{
		try{
			const response = await axios.get(FAS_FILE_GROUPS_API,{ 
				params: data,
				withCredentials: true
			})
			return response.data as Array<Group>
		}
		catch(e){
			console.log(e)
		}
	}
)