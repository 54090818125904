import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchFileVersionDetail} from '../Thunk/FileVersionDetailThunk'
interface FileVersionDetailState {
	fileid:string,
	versions: Array<Object>
}
const initialState ={
	fileid:'',
	versions:[]
} as FileVersionDetailState


const FileVersionDetailStateSlice = createSlice({
	name: 'fileVersionsState',
	initialState,
	reducers: {},
	extraReducers:(builder) => {
		builder.addCase(fetchFileVersionDetail.fulfilled,(state,action)=>{
			state.fileid= action.payload.fileid
			state.versions = action.payload.versions.map(f => {
				return({
					"summary": f.summary,
					"submitter": f.submitter,
					"versionID": f.versionID,
					"filename": f.fileName,
					"timeStamp": f.timeStamp,
					"fileSize":f.fileSize
				})
			})
		})
	}
})
export default FileVersionDetailStateSlice.reducer