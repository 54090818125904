import React from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import "./App.scss";
import {useState,useEffect} from 'react'
import {useSelector} from 'react-redux'
import { useAppSelector, useAppDispatch } from './redux/Hooks'
import NavBar from './components/NavBar/navBar'
import Buttons from './components/ButtonGroup/ButtonGroup'
import FileList from './pages/FileList/FileList'
import Login from './pages/Login/Login'
import './assets/i18n/i18n'
import Reset from './pages/Login/PasswordReset'
import axios from 'axios'
import {alert} from './redux/Slice/UserStateSlice'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@material-ui/core/Button';
declare var REDIRECT_URL: string
const AppRouter = ({ history }) => {
  return (
    <div>
      
      <BrowserRouter>
        <Router history={history}>
          <Switch>  
            <Route exact path="/">
                 <FileList/>
            </Route> 
            <Redirect push= {true} from= "*" to= "/"/> 
          </Switch>
        </Router>
      </BrowserRouter>
    </div>
  );
};

export default ({ history }) => {
  const dispatch = useAppDispatch()
  const [alertState, setAlertState] = useState(false)
  axios.interceptors.response.use((response) => {
      if(response.status === 200) {
          console.log("Good job")
      }
      return response;
  }, (error) => {
      if (error.response.status === 401){
        setAlertState(true)
      } 
      if (error.response && error.response.data) {
          return Promise.reject(error.response.data);
      }
      return Promise.reject(error.message);
  });
  const handleClose =()=>{
    const state = encodeURIComponent(`{"url":"${REDIRECT_URL}"}`)
    window.location.href= "https://broaluxadmin.auth.ap-southeast-1.amazoncognito.com/login?client_id=59ns1mv83rgo7md7fu419dgsqp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://backend.broalux.com/users/callback&state="+state
  }
  return (
    <div className="application">
        {/* <AmplifySignOut/> */}
        <Dialog
        open={alertState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Auth Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Something went wrong with your authorization, please login again
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            confirm
          </Button>
        </DialogActions>
      </Dialog>
        <AppRouter {...{ history }} />
    </div>
  );
}

// export default withAuthenticator(App);

