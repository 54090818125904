declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;

export const FAS_FILE_API = BASE_URL_FAS+'/api/v1/file'
export const FAS_FILE_API_PRE_UPLOAD = BASE_URL_FAS+'/api/v1/filepresignupload'
export const FAS_FILE_API_PRE_UPDATE = BASE_URL_FAS+'/api/v1/filepresignupdate'
export const FAS_FILE_ADMIN_API = BASE_URL_FAS+'/api/v1/fileadmin/'
export const FAS_FILE_ADMIN_CREATE_API = BASE_URL_FAS+'/api/v1/createfileadmin/'
export const FAS_FILE_GROUPS_API = BASE_URL_FAS+'/api/v1/file/group'
export const FAS_FILE_GROUP_FILE = BASE_URL_FAS+'/api/v1/group/file'
export const USER_API = BASE_URL_USER+'/login'
export const USER_LIST_API = BASE_URL_USER+'/usr/list'
export const USER_RESET_PASSWORD_API = BASE_URL_USER + '/resetpassword'
export const USER_SIGN_UP = BASE_URL_USER + '/signup'
export const USER_EDIT = BASE_URL_USER + '/usr/edit'
export const COMPANY_USER = BASE_URL_USER + '/company'
export const GROUP_USER = BASE_URL_USER + '/group'
export const USER_GROUP_USER = BASE_URL_USER + '/group/user'
export const DEPARTMENT_USER = BASE_URL_USER + '/department'
export const AUTH = BASE_URL_USER +'/auth'
