import React from 'react'
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useEffect,useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { useAppSelector, useAppDispatch } from '../../../redux/Hooks';
import {fetchPermittedFileList} from '../../../redux/Thunk/FileListThunk';
import MenuItem from '@mui/material/MenuItem'
import Button from '@material-ui/core/Button';
import { DataGrid, GridRowsProp, GridColDef ,GridCellParams,GridToolbar} from '@material-ui/data-grid';
import Checkbox from '@mui/material/Checkbox';
import './FileUploadPopOutDialog.scss'
import { isUnparsedPrepend } from 'typescript';
import {FAS_FILE_API,FAS_FILE_ADMIN_API,FAS_FILE_ADMIN_CREATE_API,FAS_FILE_API_PRE_UPLOAD,FAS_FILE_GROUP_FILE} from '../../../assets/constants/ApiUrl'
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import AddSelect from './AddSelect'
import './FileGroupManageDialog.scss'
import { fabClasses } from '@mui/material';
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
export default function FileGroupManagementDialog(props) {
	const{groupShareOpen,dialogGroupShareClose,currentUser,groupUsers,currentFile,groups,currentUserGroups} = props
	
	const currentUserGroupsList = currentUserGroups.split(',')
	const dispatch = useAppDispatch()
	const Groups = useAppSelector(state=>state.fileGroupsState.Groups)
	const userGroups = useAppSelector(state=>state.groupListState.groups)
	// const [userPermissionList, setUserPermissionList] = useState([])
	const [permittedGroup, setPermittedGroup] = useState([])
	const [groupChips, setGroupChips] = useState([])
	const [selectShow, setSelectShow] = useState(false)
	const [selectedGroupsUser, setSelectedGroupsUser] = useState([])
	const [selectedUserString, setSelectedUserString] = useState('')
	const [selectGroup, setSelectGroup] = useState('')
	useEffect(() => {
		setPermittedGroup(Groups)
	}, [Groups])
	useEffect(() => {
		var tmp = []
		console.log(permittedGroup)
		if(permittedGroup.length>0){
			tmp = permittedGroup.map(group=>{
				return(
					<Chip
						label={group}
						onDelete={handelGroupDelete(group)}	
						onClick={handleChipClick(group)}
					/>
				)
			})

			// const tmpremain = currentUserGroupsList.filter(group=>!permittedGroup.includes(group))
			
			setGroupChips(tmp)
			// setRemainGroup(tmpremain)
		}
		else{
			setGroupChips([])
			// setRemainGroup(currentUserGroupsList)
		}	
	}, [permittedGroup])
	
	const handleChipClick = (group) =>()=>{
		const tmpusers = userGroups.filter(g=>g.group === group
		)
		setSelectedGroupsUser(tmpusers)
	}
	useEffect(() => {
		if(selectedGroupsUser.length>0){
			console.log(selectedGroupsUser)
			var tmpStr = ''
			for (let i of selectedGroupsUser[0].users){
				tmpStr += i.name +','
			}
			// Remove last comma
			const groupUserStr = tmpStr.slice(0, -1)
			setSelectedUserString(groupUserStr)
		}
	}, [selectedGroupsUser])
	const handleClose = () => {
			var formData = new FormData()
			var userPermissionList = []
			for (const group of permittedGroup) {
				groupUsers.find(g => g.group === group).users.map(user => {
					userPermissionList.push({
						"userID": currentUser,
						"targetUserID": user.userID,
						"downloadPermission": true,
						"uploadPermission": true
					})
				})
			}
		
			
			axios.post(FAS_FILE_ADMIN_CREATE_API + currentFile, {
				"permissions": userPermissionList
			}, {
				withCredentials: true
			})
			axios.put(FAS_FILE_GROUP_FILE, {
					file: currentFile,
					groups: permittedGroup,
					submitter: currentUser
				}, {
					withCredentials: true
				})
				.then(() => {
					dispatch < any > (fetchPermittedFileList({
						"userID": currentUser,
						"count": 1000,
						"searchKey": '',
						"searchKeyValue": ''
					}))
				}).then(() => {
					dialogGroupShareClose()
				})}

			const handleNormalClose = () => {
				dialogGroupShareClose()
			}
			const handleGroupAdd = (g) =>{
				let tmp = [...permittedGroup,g]
				
				setPermittedGroup(tmp)
				// console.log(g)
			} 
			const handelGroupDelete = (group) => () => {
				const newtmp = permittedGroup.filter(g => g!=group)
				setPermittedGroup(newtmp)
			}
			const handleAddClick = ()=>{
				setSelectShow(true)
			}
			const hideSelect = ()=>{
				setSelectShow(false)
			}
		
			const handleReset = ()=>{
				setPermittedGroup(Groups)
				setSelectedUserString('')
			}

			const handleGroupSelectChange= (e)=>{
				handleGroupAdd(e.target.value)
				console.log(e.target.value)
				hideSelect()
			}


	return (
		<Dialog open={groupShareOpen} onClose={handleNormalClose} aria-labelledby="uploadNewFile" className="uploadNewFileDialog" maxWidth={"sm"} fullWidth={true}>
			
			<DialogTitle id="uploadNewFile">File Groups Management</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Shared Groups
				</DialogContentText>
				<div id="dialog-group-management" className="dialog-content">
					{groupChips}
					{selectShow?<TextField select id="add-new-group" label="Group"  helperText="Share to a new group"
						variant="standard" onChange={handleGroupSelectChange}>
						{
							currentUserGroupsList.map(g=>{
								return(
									<MenuItem value={g}>{g}</MenuItem>
								)
							})
						}
					  </TextField>:<></>}
					<IconButton onClick={handleAddClick}>
						<AddIcon />
					</IconButton>
				</div>
				<div className="group-user-detail">
					<h6>
						Group User
					</h6>
					<div className="group-user-detail-string">
						{selectedUserString}
					</div>
				</div>	
			</DialogContent>
			<DialogActions>
				<Button onClick={handleReset} color="primary">
					Reset
				</Button>
				<Button onClick={handleClose} color="primary">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}

