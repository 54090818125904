import { configureStore } from '@reduxjs/toolkit'
import { logger } from 'redux-logger'
import rootReducer from './RootReducers'
const initialState = {}

// type RootState = ReturnType<typeof rootReducer>
const Store = configureStore({
    reducer: rootReducer,
    // https://github.com/LogRocket/redux-logger
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        // serializableCheck: {
        //     ignoredActions: ["webex/setWebexEvent"]
        //     // ignoredPaths: ["webex.event", "webex.event.data", "webex.event.data.delta", "webex.event.data.delta.added", "webex.event.data.delta.added.0"]
        // },
        immutableCheck:true
    })
   // .concat(websocketMiddleware).concat(webexMiddleware). 
    .concat(logger),
    devTools: (process.env.NODE_ENV === 'production'),
    // Initial state
    preloadedState: initialState,
})

export default Store;

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch
