import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchFileGroupsList} from '../Thunk/FileGroupThunk'

interface FileGroupsState {
	Groups:Array<string>
}
const initialState ={
	Groups:[]	
} as FileGroupsState


const FileGroupsStateSlice = createSlice({
	name: 'fileGroupState',
	initialState,
	reducers: {},
	extraReducers:(builder) => {
		builder.addCase(fetchFileGroupsList.fulfilled,(state,action)=>{
			state.Groups = action.payload.map(g=>g.Group)
		})
	}
})
export default FileGroupsStateSlice.reducer