import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tw from './zh-tw.json';
import en from './en-us.json';



const resources = {
	'zh-TW': {
	  translation: tw,
	},
	en: {
		translation: en,
	  }
}

i18n.use(initReactI18next).init({
	resources,
	lng: 'zh-TW',             
	fallbackLng: 'zh-TW',     
	interpolation: {
	  escapeValue: true,
	},
  });
  
  export default i18n;