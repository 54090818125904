import { ContactSupportOutlined } from '@material-ui/icons'
import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchFilePermittedUserList} from '../Thunk/FilePermittedUserListThunk'
import { fetchMorePermittedFileList } from '../Thunk/MoreFileListThunk'

interface permittedUserListState {
	userPermissionList:Array<any>,
	lastKey:Object
}

const initialState = {
	userPermissionList : [],
	lastKey:null
} as permittedUserListState


const permittedUserListStateSlice = createSlice({
	name:'userPermissionListState',
	initialState,
	reducers:{},
	extraReducers:(builder)=>{
		builder.addCase(fetchFilePermittedUserList.fulfilled,(state,action)=>{
			state.userPermissionList=action.payload.sharedUserList.map((individualPermission,index)=>{
				return({
					userName:individualPermission.filterKey.slice(7),
					uploadPermission:individualPermission.permision.upload,
					downloadPermission:individualPermission.permision.download
				})
			})
			state.lastKey= action.payload.lastKey
		})
	}
})

export default permittedUserListStateSlice.reducer