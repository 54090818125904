import React from 'react';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAppSelector, useAppDispatch } from '../../../redux/Hooks';
import {useEffect,useState} from 'react'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import {fetchMorePermittedFileList} from '../../../redux/Thunk/MoreFileListThunk'
import './TablePagination.scss'
interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  }


export default (props)=>{
	const {page,count,rowsPerPage,onRowsPerPageChange, onPageChange ,searchKey,searchKeyValue,currentUser} =props
	const dispatch = useAppDispatch()
	const lastKey  = useAppSelector(state => state.fileBriefListState.lastKey)
	const handleClick =()=>{
		dispatch<any>(fetchMorePermittedFileList({"userID":currentUser,"count":5,"searchKey":searchKey,"searchKeyValue":searchKeyValue,"lastEvaluatedPartitionKey":lastKey.lastEvaluatedPartitionKey,"lastEvaluatedSortKey":lastKey.lastEvaluatedSortKey}))
	}
	const TablePaginationActions = (props: TablePaginationActionsProps)=> {
		const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, 0);
		};
	
		const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page - 1);
		};
	
		const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page + 1);
		};
	
		const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};
	
		return (
			<div className="tableActionBar">
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPageIcon />
				</IconButton>
				<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
					<KeyboardArrowLeft />
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPageIcon />
				</IconButton>
				<Button aria-label="loadMore" color="primary" className="loadMoreButton" onClick={handleClick} disabled={lastKey===null} startIcon={<AddIcon/>}>
					More
				</Button>
			</div>
		);
	}
	
	return(
		<TablePagination
			count = {count}
			page={page}
			rowsPerPage={rowsPerPage}
			onChangeRowsPerPage={onRowsPerPageChange}
			ActionsComponent={TablePaginationActions}
		/>
	)
	
}