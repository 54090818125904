import React from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import {useEffect,useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { useAppSelector, useAppDispatch } from '../../../redux/Hooks';
import Button from '@material-ui/core/Button';
import { DataGrid, GridRowsProp, GridColDef ,GridCellParams} from '@material-ui/data-grid';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import {FAS_FILE_API} from '../../../assets/constants/ApiUrl'
import './FileVersionDetailDialog.scss'
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
export default function FileUploadPopOutDialog(props) {
	const{downloadOpen,downloadDialogClose,downloadPermission,owner,currentUser} = props
	const [downloadUrl, setDownloadUrl] = useState('')
	const dispatch = useAppDispatch()
	const fileid = useAppSelector(state=>state.fileVersionsState.fileid)
	const versionsList = useAppSelector(state => state.fileVersionsState.versions)
	var history = useHistory()
	const handleClose = () => {
		downloadDialogClose() 
	};
	const handleDownload =(versionID,filename,fileid)=> (e)=>{
		axios.get(FAS_FILE_API+"/"+fileid+'/download',{params:{versionID,filename},withCredentials: true}).then((response)=>{window.open(response.data.File_url)})
	}
	const columns: GridColDef[] = [
		{field: 'col1', headerName: 'FileName', flex: 280,renderCell:(params: GridCellParams)=>{
			return(
				<Tooltip title={params.value}>
					<div>{params.value}</div>
				</Tooltip>
			)
		}},
		{field: 'col3', headerName: 'Note', flex: 250,renderCell:(params: GridCellParams)=>{
			return(
				<Tooltip title={params.value}>
					<div>{params.value}</div>
				</Tooltip>
			)
		}},
		{field: 'col4', headerName: 'Time', flex: 120,renderCell:(params: GridCellParams)=>{
			return(
				<Tooltip title={params.value}>
					<div>{params.value}</div>
				</Tooltip>
			)
		}},
		{field: 'col5', headerName: 'Size', flex: 80},
		{field: 'col6', headerName: 'Download', flex: 80,renderCell: (params: GridCellParams) =>{
			return(<IconButton disabled={!downloadPermission} onClick={handleDownload(params.value.versionID,params.value.filename,fileid)}><CloudDownloadIcon/></IconButton>)
		}}			
	]

	const rows: GridRowsProp = versionsList.map((version,index)=>{
		return({
			id:index+1,
			col1:version.filename,
			col3:version.summary,
			col4:version.timeStamp,
			col5:version.fileSize,
			col6:{
				"versionID":version.versionID,
				"filename":version.filename
		}
		})
	})
	
	const userRows = rows.length>0?[rows[0]]:[]


	return (
		<Dialog maxWidth={'lg'} fullWidth scroll={'paper'} className="FileVersionDetailDialog" open={downloadOpen} onClose={handleClose} aria-labelledby="uploadNewFile">
			<DialogTitle id="versionsFile">The version list of the file</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Download a specific version from below list
				</DialogContentText>
				<DataGrid 
					className="fileDownloadVersions"
					rows={currentUser!=owner?userRows:rows} 
					columns={columns} 
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}
