import { FiberPinTwoTone } from '@material-ui/icons';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {FAS_FILE_API} from '../../assets/constants/ApiUrl'
import axios from 'axios'
import AxiosInterceptor from './AxiosInterceptor';
// AxiosInterceptor()


// console.log("bentest1")
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
interface FileBrief {
	fileid:string
	title: string,
	description: string,
	filterKey: string,
	owner:string,
	ownerName:string,
	visibility:boolean,
	company:string,
	createTime:string,
	permision: Permission
}
interface Permission {
	upload:boolean,
	download:boolean
}
interface PermittedFileList{
	permissionList: Array<FileBrief>
	lastKey:Object
}


interface InputData{
	userID:string,
	count:number,
	searchKey:string,
	searchKeyValue:string
}
export const fetchPermittedFileList = createAsyncThunk(
	'file/filebrief',
	async(data:InputData,thunkapi)=>{
		try{
			const response = await axios.get(FAS_FILE_API,{ 
				params: data,
				withCredentials: true
			})
			return response.data as PermittedFileList
		}
		catch(e){
			console.log(e)
		}
	}
)