import 'react-hot-loader/patch';
import { AppContainer } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from "history";
import App from './App';
import { Provider } from 'react-redux';
import Store from './redux/Store';
// import "./index.scss";
import { CookiesProvider } from 'react-cookie';
const history = createBrowserHistory({ basename: "" });

const render = (Component) => {
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={Store}>
        <AppContainer>
          <Component history={history} />
        </AppContainer>
      </Provider>
    </CookiesProvider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const newApp = require('./App').default;
    render(newApp);
  });
}
