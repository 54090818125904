import React from 'react'
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useEffect,useState} from 'react'
import Button from '@material-ui/core/Button';

export default (props)=>{
	const{deleteFileTitle,deleteFileID,deleteConfirmationOpen,deleteConfirmationClose,handleDelete} = props
	const handleDeleteConfirmationNormalClose = ()=>{
		deleteConfirmationClose()
	}
	return(
		<Dialog open={deleteConfirmationOpen} onClose={handleDeleteConfirmationNormalClose}>
			<DialogTitle>Delete Confirmation</DialogTitle>
			<DialogContent>{deleteFileTitle} will be deleted, please confirm.</DialogContent>
			<DialogActions>
				<Button onClick={handleDeleteConfirmationNormalClose} color="primary">
					cancel
				</Button>
				<Button onClick={handleDelete(deleteFileID)} color="primary">
					confirm
				</Button>
			</DialogActions>
		</Dialog>
	)


}