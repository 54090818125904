import { createAsyncThunk } from '@reduxjs/toolkit'
import {AUTH} from '../../assets/constants/ApiUrl'
import axios from 'axios'

interface Mydata{
	status:string
}

export const fetchLoginAuthConfirm = createAsyncThunk(
	'users/AuthConfirm',
	async(thunkAPI) =>{
		try{
			const response = await axios.post(AUTH,{ withCredentials: true })
			return response.data as Mydata
		}
		catch(e){
			console.log(e)
		}
	}
)
