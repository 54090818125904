import { createAsyncThunk } from '@reduxjs/toolkit';
import {FAS_FILE_ADMIN_API} from '../../assets/constants/ApiUrl'
import axios from 'axios';
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
interface permittedUserList {
	sharedUserList:Array<individualPermission>
	lastKey:Object
}

interface individualPermission {
	filterKey:string,
	owner:string,
	visibility:Boolean,
	createTime:string,
	permision:permission,
	description:string,
	company:string,
	title:string,
	fileid:string
}

interface permission {
	upload:Boolean,
	download:Boolean
}


export const fetchFilePermittedUserList = createAsyncThunk(
	'file/permittedUserList',
	async(keyName:string,thunkapi)=>{
		try{
			const response = await axios.get(FAS_FILE_ADMIN_API+keyName,{withCredentials: true})
			return response.data as permittedUserList
		}
		catch(e){
			console.log(e)
		}
	}
)