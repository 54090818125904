import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchPermittedFileList} from '../Thunk/FileListThunk'
import {fetchMorePermittedFileList} from '../Thunk/MoreFileListThunk'
interface FileListState {
	files: Array<Object>,
	lastKey: Object,
	loading:boolean
}
const initialState ={
	files:[],
	lastKey:null,
	loading:false
} as FileListState


const FileListStateSlice = createSlice({
	name: 'fileListState',
	initialState,
	reducers: {},
	extraReducers:(builder) => {
		builder.addCase(fetchPermittedFileList.fulfilled,(state,action)=>{
			state.lastKey = action.payload.lastKey?{"lastEvaluatedPartitionKey":action.payload.lastKey.filterKey,"lastEvaluatedSortKey":action.payload.lastKey.fileid} : null
			state.loading = false
			state.files = action.payload.permissionList.map(f => {
				return({
					"title":f.title,
					"description":f.description,
					"owner":f.owner,
					"ownerName":f.ownerName,
					"filterKey":f.filterKey,
					"fileid":f.fileid,
					"company":f.company,
					"createTime":f.createTime,
					"permision":f.permision,
				})
			
			})
		}).addCase(fetchPermittedFileList.pending,(state,action)=>{
			state.loading = true
		}).addCase(fetchMorePermittedFileList.fulfilled,(state,action)=>{
			state.lastKey = action.payload.lastKey?{"lastEvaluatedPartitionKey":action.payload.lastKey.filterKey,"lastEvaluatedSortKey":action.payload.lastKey.fileid} : null
			state.loading = false
			const newList = action.payload.permissionList.map(f => {
				return({
					"title":f.title,
					"description":f.description,
					"owner":f.owner,
					"filterKey":f.filterKey,
					"fileid":f.fileid,
					"company":f.company,
					"createTime":f.createTime,
					"permision":f.permision,
				})
			
			})
			for (let i of newList){state.files.push(i)}
		}).addCase(fetchMorePermittedFileList.pending,(state,action)=>{
			state.loading = true
		})
	}
})
export default FileListStateSlice.reducer