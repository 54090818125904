import { createAsyncThunk } from '@reduxjs/toolkit'
import {GROUP_USER,USER_GROUP_USER} from '../../assets/constants/ApiUrl'
import axios from 'axios'
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
interface Mydata{
	groups: Array<groupInfo>,
}
interface groupInfo{
	group:string,
	users:Array<user>,
}
interface user{
	name:string,
	userID:string
}
interface inputdata{
	groups:Array<string>
}
export const fetchGroupList = createAsyncThunk(
	'users/group/List',
	async(data:inputdata,thunkAPI) =>{
		try{
			const response = await axios.post(USER_GROUP_USER,
				data ,
			{
				withCredentials: true
			})
			console.log(response)
			return response.data as Mydata
		}
		catch(e){
			console.log(e)
		}
	}
)