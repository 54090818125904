import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchGroupList} from '../Thunk/GroupListThunk'

interface GroupListState{
	groups:Array<groupAttributes>
}
interface groupAttributes{
	group:string,
	users:Array<user>,
}
interface user{
	name:string,
	userID:string
}

const initialState = {
	groups:[]
} as GroupListState


const groupListSlice = createSlice({
	name:'groupListState',
	initialState,
	reducers:{},
	extraReducers:(builder)=>{
		builder.addCase(fetchGroupList.fulfilled,(state,action)=>{
			state.groups= action.payload.groups.map(group=>{
				return({
					group:group.group,
					users:group.users
				})
			})
		})
	}
})

export default groupListSlice.reducer