import { createSlice,PayloadAction } from '@reduxjs/toolkit'
interface langState {
	lang:string
}
const initialState= {
	lang:"zh-TW"
} as langState
const i18nSlice = createSlice({
	name:"i18nLang",
	initialState,
	reducers:{
		switchLang:(state, action: PayloadAction<string>)=>{state.lang=action.payload}
	}
})

export const {switchLang} = i18nSlice.actions

export default i18nSlice.reducer