import { TryOutlined } from '@mui/icons-material'
import { createSlice,PayloadAction } from '@reduxjs/toolkit'
// import {fetchLoginAuth} from '../Thunk/LoginThunk'
// import {respondResetPassword} from '../Thunk/ResetPasswordThunk'
interface LoginState {
	user: string,
	name:string,
	company:string,
	department:string,
	group:string,
	phone:string,
	email:string,
	loggedIn:boolean,
	loading:boolean,
	ChallengeName:string,
	Session:string,
	alert:boolean
  }
const initialState = { 
	user : '',
	name:'',
	company:'',
	department:'',
	group:'',
	phone:'',
	email:'',
	loggedIn:false,
	loading:false,
	ChallengeName:'',
	Session:'',
	alert:false
} as LoginState

const UserStateSlice = createSlice({
	name: 'userState',
	initialState,
	reducers: {
		init:(state,action)=>{
			state.user = action.payload.username
			state.name = action.payload.name
			state.company = action.payload['custom:custom:company']
			state.department = action.payload['custom:custom:department']
			state.email = action.payload.email
			state.phone = action.payload.phone_number
			state.group = action.payload['custom:custom:group']
			state.loggedIn = true
		},
		alert:(state,action) =>{
			state.alert = true
		}
	}
	// extraReducers:(builder) => {
	// 	builder.addCase(fetchLoginAuth.fulfilled,(state,action)=>{
	// 		if(action.payload.hasOwnProperty('ChallengeName')){	
	// 			state.user = action.payload.ChallengeParameters.USER_ID_FOR_SRP
	// 			state.ChallengeName = action.payload.ChallengeName
	// 			state.Session = action.payload.Session
	// 			state.loading=false
	// 		}else
	// 		{
	// 			state.loggedIn = true	
	// 			state.user = action.payload.userName
	// 			state.name = action.payload.UserAttributes.find(attr=>attr.Name==="name")?action.payload.UserAttributes.find(attr=>attr.Name==="name").Value:""
	// 			state.company = action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:company")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:company").Value:""
	// 			state.department = action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:department")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:department").Value:""
	// 			state.group =action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:group")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:group").Value:""
	// 			state.email = action.payload.UserAttributes.find(attr=>attr.Name==="email")?action.payload.UserAttributes.find(attr=>attr.Name==="email").Value:""
	// 			state.phone = action.payload.UserAttributes.find(attr=>attr.Name==="phone_number")?action.payload.UserAttributes.find(attr=>attr.Name==="phone_number").Value:""
	// 			state.loading=false
	// 		}
	// 	}).addCase(fetchLoginAuth.pending, (state, action) => {
	// 		state.loading = true
	// 	}).addCase(respondResetPassword.fulfilled, (state, action) => {
	// 		state.loggedIn = true
	// 		state.user = action.payload.userName
	// 		state.name = action.payload.UserAttributes.find(attr=>attr.Name==="name")?action.payload.UserAttributes.find(attr=>attr.Name==="name").Value:""
	// 			state.company = action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:company")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:company").Value:""
	// 			state.department = action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:department")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:department").Value:""
	// 			state.group =action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:group")?action.payload.UserAttributes.find(attr=>attr.Name==="custom:custom:group").Value:""
	// 			state.email = action.payload.UserAttributes.find(attr=>attr.Name==="email")?action.payload.UserAttributes.find(attr=>attr.Name==="email").Value:""
	// 			state.phone = action.payload.UserAttributes.find(attr=>attr.Name==="phone_number")?action.payload.UserAttributes.find(attr=>attr.Name==="phone_number").Value:""
	// 		state.loading = false
	// 	}).addCase(respondResetPassword.pending, (state, action) => {
	// 		state.loading = true
	// 	})
	// 	}
		})
export const {init,alert} = UserStateSlice.actions
export default UserStateSlice.reducer
