import React from 'react';
import {useEffect,useState} from 'react'
import { DataGrid, GridRowsProp, GridColDef ,GridCellParams,GridOverlay} from '@material-ui/data-grid';
import { useAppSelector, useAppDispatch } from '../../redux/Hooks';
import {fetchPermittedFileList} from '../../redux/Thunk/FileListThunk';
import './FileList.scss'
import headerLogo from '../../assets/images/header/logo-white.png';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import axios from 'axios';
import FileUploadDialog from './components/FileUploadPopOutDialog'
import FileDownloadDialog from './components/FileVersionDetailDialog'
import customPagination from './components/TablePagination'
import {fetchFileVersionDetail} from '../../redux/Thunk/FileVersionDetailThunk'
import { FlashOnRounded } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import FileEditPopOutDialog from './components/FileEditPopOutDialog'
import FileEditPopOutDialog2 from './components/FileEditPopOutDialog_v2'
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteConfirmationDialog from './components/DeleteConfirmationDialog' 
import {fetchUserList} from '../../redux/Thunk/UserListThunk'
import LinearProgress from '@mui/material/LinearProgress';
import {FAS_FILE_API} from '../../assets/constants/ApiUrl';
import {fetchGroupList} from '../../redux/Thunk/GroupListThunk'
import {fetchLoginAuthConfirm} from '../../redux/Thunk/AuthConfirmThunk'
import {AUTH,USER_API,USER_GROUP_USER} from '../../assets/constants/ApiUrl'
import {init} from '../../redux/Slice/UserStateSlice'
import LogoutIcon from '@mui/icons-material/Logout';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import {fetchFileGroupsList} from '../../redux/Thunk/FileGroupThunk'
import FileGroupManagementDialog from './components/FileGroupManageDialog'
declare var BASE_URL_FAS: string;
declare var BASE_URL_USER: string;
declare var REDIRECT_URL: string
export default function FileDetailBlock() {
	const dispatch = useAppDispatch()
	const fileList = useAppSelector(state => state.fileBriefListState.files)
	// temp user reducer application..................................
	const currentUser = useAppSelector(state => state.userState.user)
	const currentUserName = useAppSelector(state => state.userState.name)
	const currentUserCompany = useAppSelector(state=>state.userState.company)
	const currentUserGroups = useAppSelector(state=>state.userState.group)
	const groupUsers = useAppSelector(state=>state.groupListState.groups)
	const loggedIn = useAppSelector(state=>state.userState.loggedIn)
	const userGroups = useAppSelector(state=>state.userState.group)
	const Groups = useAppSelector(state=>state.fileGroupsState.Groups)
	// temp user reducer application.........................

	const lastKey  = useAppSelector(state => state.fileBriefListState.lastKey)
	const fileListingLoading = useAppSelector(state=> state.fileBriefListState.loading)
	const AuthConfirmStatus = useAppSelector(state=>state.AuthConfirmState.status)
	const [currentFileOwner, setCurrentFileOwner] = useState("")
	const [searchKey, setSearchKey] = useState('')
	const [searchKeyValue, setSearchKeyValue] = useState('')
	const [open, setOpen] = useState(false)
	const [updateOpen, setUpdateOpen] = useState(false)
	const [downloadOpen, setDownloadOpen] = useState(false)
	const [downloadPermission, setDownloadPermission] = useState(true)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState<number>(10)
	const [editFileID, setEditFileID] = useState('')
	const [editFileUploadPermission, setEditFileUploadPermission] = useState(true)
	const [editFileOwner, setEditFileOwner] = useState('')
	const [editFileTitle, setEditFileTitle] = useState('')
	const [editFileDescription, setEditFileDescription] = useState('')
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
	const [deleteFileTitle, setDeleteFileTitle] = useState('')
	const [deleteFileID, setDeleteFileID] = useState('')
	const [groupShareOpen, setGroupShareOpen] = useState(false)
	const [groupShareId, setGroupShareId] = useState('')
	const CustomLoadingOverlay= () => {
		return (
		  <GridOverlay>
			<div style={{ position: 'absolute', top: 0, width: '100%' }}>
			  <LinearProgress />
			</div>
		  </GridOverlay>
		);
	  }
	useEffect(() => {
		// dispatch<any>(fetchLoginAuthConfirm())
		axios.post(
			AUTH,{},{ withCredentials: true }
			).catch((error)=>{
				const state = encodeURIComponent(`{"url":"${REDIRECT_URL}"}`)
				window.location.href= "https://broaluxadmin.auth.ap-southeast-1.amazoncognito.com/login?client_id=59ns1mv83rgo7md7fu419dgsqp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://backend.broalux.com/users/callback&state="+state
				// axios.post(
				// 	USER_API,{},{}
				// )
			}).then((response)=>{
				dispatch<any>(init(response.data))
				
			})
	}, [])
	useEffect(() => {
		if(loggedIn){
			dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":searchKey,"searchKeyValue":searchKeyValue}))
			
		}
	}, [loggedIn])
	useEffect(() => {
		if(userGroups){
			const data = userGroups.split(',')
			dispatch<any>(fetchGroupList({groups:data}))
		}
	}, [userGroups])
	useEffect(() => {
		console.log(searchKey)
	}, [searchKey])
	

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchKey(event.target.value as string);
	};
	const handleSearchKeyValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	setSearchKeyValue(event.target.value as string);
	};
	const handleSearch = (event)=>{
		dispatch<any>(fetchPermittedFileList({
			"userID":currentUser,
			"count":1000,
			"searchKey":searchKey,
			"searchKeyValue":searchKeyValue
		}))
	}
	const handleClearSearch = ()=>{
		setSearchKey('')
		setSearchKeyValue('')
		dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":searchKey,"searchKeyValue":searchKeyValue}))
	}
	const handleRefresh = ()=>{
		dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":searchKey,"searchKeyValue":searchKeyValue}))
	}

	//open setting for the upload dialog
	const handleClickOpen = () => {
		setOpen(true);
	  };
	const dialogClose =()=>{
		setOpen(false)
	}
	const dialogGroupShareClose =()=>{
		setGroupShareOpen(false)
	}
	//download setting for the download dialog
	const handleClickDownloadOpen = (keyname,downloadPermission,owner)=>e => {
		dispatch<any>(fetchFileVersionDetail(keyname))
		setDownloadPermission(downloadPermission)
		setCurrentFileOwner(owner)
		setDownloadOpen(true);
	  };
	const downloadDialogClose =()=>{
		setDownloadOpen(false)
	}
	const handleClickUpdateOpen =(fileid,uploadPermission,owner,title,description)=> () => {
		dispatch<any>(fetchFileGroupsList({"file":fileid}))
		setEditFileID(fileid)
		setEditFileUploadPermission(uploadPermission)
		setEditFileOwner(owner)
		setEditFileTitle(title)
		setEditFileDescription(description)
		setUpdateOpen(true);
	  };
	const dialogUpdateClose =()=>{
		setUpdateOpen(false)
	}
	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setRowsPerPage(parseInt(event.target.value));
		console.log('pagesize changed')
		setPage(0);
	};
	const handleDeleteConfirmationOpen= (title,fileid) => () =>{
		setDeleteConfirmationOpen(true)
		setDeleteFileTitle(title)
		setDeleteFileID(fileid)
	}
	const deleteConfirmationClose = ()=>{
		setDeleteConfirmationOpen(false)
	}
	const handleDelete = (fileid)=>(e)=>{
		axios.delete(FAS_FILE_API+"/" + fileid,{withCredentials: true}).then((response)=>{
			setDeleteConfirmationOpen(false)
			dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":searchKey,"searchKeyValue":searchKeyValue}))
		})
	}
	const handleShareOpen = (fileid,owner)=>(e)=>{
		dispatch<any>(fetchFileGroupsList({"file":fileid,owner}))
		setGroupShareOpen(true)
		setGroupShareId(fileid)
	}
	const handleLogout = ()=>{
		const state = encodeURIComponent(`{"url":"${REDIRECT_URL}"}`)
		window.location.href= "https://broaluxadmin.auth.ap-southeast-1.amazoncognito.com/logout?client_id=59ns1mv83rgo7md7fu419dgsqp&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://backend.broalux.com/users/callback&state="+state
		// window.location.href = "https://broaluxadmin.auth.ap-southeast-1.amazoncognito.com/logout?client_id=59ns1mv83rgo7md7fu419dgsqp&logout_uri=https://www.google.com"
	}

	const searchKeyPool = [
		{
			label: "Any",
			value: "any"
		},
		{
			label: "Company",
			value: "companyy"
		},
		{
			label: 'Title',
			value: "title"
		},
		{
			label: "Description",
			value: "description"
		},
		{
			label: "Owner",
			value: "owner"
		},
		{
			label: "Created Time",
			value: "createdTime"
		}
	]



	const rows: GridRowsProp = fileList.map((file,index)=>{
		return({
			id: index+1, 
			col4: file.title, 
			col2: file.company,
			col3:file.description,
			col6:file.ownerName,
			col7:{
				"fileid":file.fileid,
				"downloadPermission":file.permision.download,
				"owner":file.owner,
				"uploadPermission":file.permision.upload,
				"title":file.title,
				"description":file.description
			}
		})
	})
	
	  
	const columns: GridColDef[] = [
	{ field: 'col4', headerName: 'Title', flex: 200 ,minWidth: 300,renderCell:(params: GridCellParams)=>{
		return(
			<Tooltip title={params.value}>
				<div>{params.value}</div>
			</Tooltip>
		)
	}},
	{ field: 'col3', headerName: 'Description', flex: 250,minWidth: 300,renderCell:(params: GridCellParams)=>{
		return(
			<Tooltip title={params.value}>
				<div>{params.value}</div>
			</Tooltip>
		)
	} },
	// { field: 'col5', headerName: 'fileid', width: 300 },
	{ field: 'col6', headerName: 'Owner', flex: 80, minWidth: 130,},
	{ field: 'col2', headerName: 'Company', flex: 80,minWidth: 130, },
	{ field: 'col7', headerName: 'Operations', flex: 100, minWidth: 210,renderCell: (params: GridCellParams) =>{
		return(
			<div>
				<Tooltip title="Download" placement="top">
					<IconButton onClick={handleClickDownloadOpen(params.value.fileid,params.value.downloadPermission,params.value.owner)}><CloudDownloadIcon/></IconButton>
				</Tooltip>
				<Tooltip title="Edit" placement="top">
					<IconButton disabled={params.value.owner!=currentUser} onClick={handleClickUpdateOpen(params.value.fileid,params.value.uploadPermission,params.value.owner,params.value.title,params.value.description)}><EditIcon/></IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement="top">
					<IconButton disabled={params.value.owner!=currentUser} onClick={handleDeleteConfirmationOpen(params.value.title,params.value.fileid)}><DeleteIcon/></IconButton>
				</Tooltip>
				<Tooltip title="Share" placement="top">
					<IconButton disabled={params.value.owner!=currentUser} onClick={handleShareOpen(params.value.fileid,params.value.owner)}><FolderSharedIcon/></IconButton>
				</Tooltip>
			</div>
			)
	} ,},
	];

	return (
		<div>
			<FileUploadDialog currentUser={currentUser} currentUserName={currentUserName} currentUserCompany={currentUserCompany} open={open} dialogClose={dialogClose} currentUserGroups={currentUserGroups} groupUsers={groupUsers}/>
			<FileDownloadDialog downloadOpen={downloadOpen} downloadDialogClose={downloadDialogClose} downloadPermission={downloadPermission} currentUser={currentUser} owner={currentFileOwner}/>
			{/* <FileEditPopOutDialog currentUser={currentUser} open={updateOpen} dialogClose={dialogUpdateClose} editFileID={editFileID} users={users} editFileUploadPermission={editFileUploadPermission} editFileOwner={editFileOwner} editFileTitle={editFileTitle} editFileDescription={editFileDescription} handleRefresh={handleRefresh}/> */}
			<FileEditPopOutDialog2 currentUser={currentUser} currentUserCompany={currentUserCompany} open={updateOpen} editFileID={editFileID} dialogClose={dialogUpdateClose} editFileTitle={editFileTitle} editFileDescription={editFileDescription} handleRefresh={handleRefresh}/>
			<DeleteConfirmationDialog   deleteFileTitle={deleteFileTitle} deleteFileID={deleteFileID} deleteConfirmationOpen={deleteConfirmationOpen}  deleteConfirmationClose={deleteConfirmationClose} handleDelete={handleDelete}  />
			<FileGroupManagementDialog groupShareOpen={groupShareOpen} dialogGroupShareClose={dialogGroupShareClose} currentUser={currentUser} groupUsers={groupUsers} currentUserGroups={currentUserGroups} groups={Groups} currentFile={groupShareId}/>
			<div className="titleBar">
				<div className="logoDiv">
					<img src={headerLogo}/>
				</div>
				<FileCopyIcon/>
				<div className="titleText">
					資訊檔案存取交換系統(File Access System)
				</div>
				{/* <IconButton onClick={handleLogout}>
					<LogoutIcon/>
				</IconButton> */}
				<Button className="logOutButton" onClick={handleLogout} startIcon={<LogoutIcon/>}>Log Out</Button>
				{/* <TextField 
					select
					id="temporalUserSwitch"
					label="CurrentUser"
					value={currentUser}
					onChange={handleUserChange}
					className="currentUser"
				>
					{users.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.value}
						</MenuItem>
					))}
				</TextField> */}
			</div>
			<div className="fasContainer">
				<div className="searchBar">
					<TextField 
					id="searchKeyValueInput" 
					label="Search KeyWord"  
					defaultValue="" 
					onChange={handleSearchKeyValueChange}
					className="searchKeyValueInput"
					fullWidth
					/>
					<TextField
					id="searchKeySelect"
					select
					label="Catagory"
					value={searchKey}
					onChange={handleChange}
					placeholder="The filter key"
					className="searchKeyDropDown"
					>
					{searchKeyPool.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
					</TextField>
					<Tooltip title="search" placement="top">
						<IconButton aria-label="search" color="primary" className="searchButton" onClick={handleSearch}>
							<SearchIcon/>
						</IconButton>
					</Tooltip>
					<Tooltip title="clear" placement="top">
						<IconButton aria-label="clearSearch" color="primary" className="clearSearch" onClick={handleClearSearch}>
							<RefreshIcon/>
						</IconButton>
					</Tooltip>
					
					<Button aria-label="upload" color="primary" className="uploadButton" onClick={handleClickOpen} startIcon={<CloudUploadIcon/>}>
						創建檔案
					</Button>
				</div>
				<div className="dashBoard">
					<DataGrid 
						rows={rows} 
						columns={columns} 
						pagination 
						pageSize={rowsPerPage}
						page={page}
						components={{LoadingOverlay: CustomLoadingOverlay,Pagination:customPagination}} 
						componentsProps ={{pagination:{
							searchKey,
							searchKeyValue,
							page,
							count:rows.length,
							rowsPerPage,
							currentUser,
							onPageChange:handleChangePage,
							onRowsPerPageChange:handleChangeRowsPerPage
						}}}
						loading={fileListingLoading}
						disableSelectionOnClick={true}
					/>
				</div>
			</div>
			
		</div>
		
	);
}
