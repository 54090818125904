import React from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useEffect,useState} from 'react'
import TextField from '@material-ui/core/TextField'
import { useAppSelector, useAppDispatch } from '../../../redux/Hooks'
import {fetchPermittedFileList} from '../../../redux/Thunk/FileListThunk'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import { DataGrid, GridRowsProp, GridColDef ,GridCellParams,GridToolbar} from '@material-ui/data-grid'
import Checkbox from '@mui/material/Checkbox'
import './FileUploadPopOutDialog.scss'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionActions from '@material-ui/core/AccordionActions'
import { isUnparsedPrepend } from 'typescript'
import {fetchFileGroupsList} from '../../../redux/Thunk/FileGroupThunk'
import LinearProgress from '@mui/material/LinearProgress'
import {FAS_FILE_API,FAS_FILE_ADMIN_API,FAS_FILE_API_PRE_UPDATE} from '../../../assets/constants/ApiUrl'
import FileGroupBox from './FileGroupDialog'
declare var BASE_URL_FAS: string
declare var BASE_URL_USER: string
export default function FileUploadPopOutDialog(props) {
	const{open,dialogClose,currentUser,currentUserCompany,editFileID,editFileTitle,editFileDescription,handleRefresh} = props
	const dispatch = useAppDispatch()
	const [fileTitle, setFileTitle] = useState("")
	const [fileDescription, setFileDescription] = useState("")
	const [progress, setProgress] = useState(0)
	const [file, setFile] = useState<File>(null)
	const [userPermissionList, setUserPermissionList] = useState([])
	const [fileEditDescription, setFileEditDescription] = useState('')
	const [fileEditTitle, setFileEditTitle] = useState('')
	const Groups = useAppSelector(state=>state.fileGroupsState.Groups)

	// useEffect(() => {
	// 	dispatch<any>(fetchFileGroupsList({"file":editFileID}))
	// }, [])




	const handleFileChange = (e: React.FormEvent) => {
		const files = (e.target as HTMLInputElement).files
		if (files && files.length > 0) {
			setFile(files[0])
		}
	}
	const handleClose = () => {
		
		var formData = new FormData()
		formData.append("submitter",currentUser)
		formData.append("summary",fileDescription)
		formData.append("filename",file.name)
		axios.post(FAS_FILE_API_PRE_UPDATE,{
			key:editFileID
		},{
			withCredentials:true
		}).then((response)=>{
			let form = new FormData()
			Object.keys(response.data.fields).forEach(key=> form.append(key,response.data.fields[key]))
			form.append('file',file)
			axios.post(
				response.data.url,
				form,{
					onUploadProgress: data => {

					//Set the progress value to show the progress bar
			
					setProgress(Math.round((100 * data.loaded) / data.total))
			
				  }
				}).then(()=>{
					axios.put(FAS_FILE_API+"/"+editFileID,formData,{
						headers:{
							'Content-Type': 'multipart/form-data'
						},
						withCredentials: true
					})
					.then(()=>{
						dispatch<any>(fetchPermittedFileList({"userID":currentUser,"count":1000,"searchKey":'',"searchKeyValue":''}))
						setProgress(0)
						dialogClose()
					})
				})
		})
		const data={
			"title":fileEditTitle?fileEditTitle:editFileTitle,
			"description":fileEditDescription?fileEditDescription:editFileDescription
		}
		if(fileEditTitle || fileEditDescription){
			axios.put(FAS_FILE_API+'/'+editFileID+'/edit',data,{
				headers:{
					'Content-Type': 'application/json'
				},
				withCredentials: true
			}).then(response=>{
				setFileEditTitle('')
				setFileEditDescription('')
				handleRefresh()
			})
			}
	}
	const handleNormalClose = ()=>{
		dialogClose()
	}
	const handleEditClose = () => {
		dialogClose()
		const data={
			"title":fileEditTitle?fileEditTitle:editFileTitle,
			"description":fileEditDescription?fileEditDescription:editFileDescription
		}
		if(fileEditTitle || fileEditDescription){
			axios.put(FAS_FILE_API+'/'+editFileID+'/edit',data,{
				headers:{
					'Content-Type': 'application/json'
				},
				withCredentials: true
			}).then(response=>{
				setFileEditTitle('')
				setFileEditDescription('')
				handleRefresh()
			})
			}
		
	}


	return (
		<Dialog open={open} onClose={handleNormalClose} aria-labelledby="uploadNewFile" className="uploadNewFileDialog" maxWidth={"lg"}>
			<DialogTitle id="uploadNewFile">Update</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Update a new version or ammend the title and the description
				</DialogContentText>
				
				<form className="editFile">
					<TextField autoFocus margin="normal" id="title" label="Title" fullWidth defaultValue={editFileTitle}
						onChange={(e)=>{
							setFileEditTitle(e.target.value)
						}}
					/>
					<TextField autoFocus id="Description" label="Desciption" fullWidth defaultValue={editFileDescription}
						onChange={(e)=>{
							setFileEditDescription(e.target.value)
						}}
					/>	
				</form>
				<form className="uploadFileForm">
					
					<TextField
						autoFocus
						margin="normal"
						label="File"
						id="file"
						type="file"
						fullWidth
						onChange={handleFileChange}
					/>
					
					<TextField
						autoFocus
						id="Description"
						label="Note"
						fullWidth
						onChange={(e)=>{
							setFileDescription(e.target.value)
						}}
					/>
				</form>	
				<LinearProgress variant="determinate" value={progress} />
				{/* <FileGroupBox hide={currentUserCompany!=='Broalux'} groups={Groups}/> */}
				{/* <DataGrid className="awsUserList" rows={rows} columns={columns} components={{Toolbar: GridToolbar}}/>		 */}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleEditClose} color="primary">
					Edit
				</Button>
				<Button onClick={handleNormalClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleClose} color="primary">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}
