import { createSlice,PayloadAction } from '@reduxjs/toolkit'
import {fetchLoginAuthConfirm} from '../Thunk/AuthConfirmThunk'
interface AuthState {
	status:Boolean
}

const initialState = {
	status:false
} as AuthState

const AuthStateSlice = createSlice({
	name: 'AuthStateSlice',
	initialState,
	reducers:{},
	extraReducers:(builder)=>{
		builder.addCase(fetchLoginAuthConfirm.fulfilled,(state,action)=>{
			state.status = true
		}).addCase(fetchLoginAuthConfirm.rejected,(state,action)=>{
			state.status = false
		})
	}

})

export default AuthStateSlice.reducer