import { combineReducers } from 'redux';
import FileBriefListReducer from './Slice/FileListSlice'
import I18nReducer from './Slice/I18nSlice'
import FileVersionDetailReducer from './Slice/FileDetailSlice'
import FilePermittedUserReducer from './Slice/FilePermittedUserSlice'
import UserReducer from './Slice/UserStateSlice'
import AwsUserListReducer from './Slice/UserListSlice'
import GroupListReducer from './Slice/GroupListSlice'
import AuthConfirmReducer from './Slice/AuthSlice'
import FileGroupsReducer from './Slice/FileGroupsSlice'
//=====================================================================================================
// Root reducer will combine all reducers here and pass to the store used by the main.tsx
//=====================================================================================================
const rootReducer = combineReducers({
  // Define a top-level state field named `login`, handled by `loginReducer`
  userState:UserReducer,
  langState: I18nReducer,
  fileBriefListState:FileBriefListReducer,
  fileVersionsState:FileVersionDetailReducer,
  filePermittedUsersState:FilePermittedUserReducer,
  groupListState:GroupListReducer,
  AuthConfirmState:AuthConfirmReducer,
  fileGroupsState:FileGroupsReducer
});

export default rootReducer;
